import { useUserStore } from "@/stores/user";

export default defineNuxtRouteMiddleware(async (to, from) => {
  const userStore = useUserStore();
  console.log("inside auth middleware");
  const currentUser = await getCurrentUser();
  const properlyLoggedIn = userStore.signedIn;

  if (!properlyLoggedIn) {
    return navigateTo("/");
  } else if (properlyLoggedIn && !currentUser.emailVerified) {
    return navigateTo("/verify");
  }
});
